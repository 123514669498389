<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title color="dark">Aggiungi Foto</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Aggiungi Foto</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="sendingPhotos" class="loading_spinner">
        <ion-text color="dark">
          <h5>Salvataggio delle foto in corso</h5>
        </ion-text>
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>

      <div v-else>
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div>
                <ion-text color="dark">
                  <h5>Clicca sul pulsante blu per aggiungere foto</h5>
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-button
                color="danger"
                size="default"
                expand="block"
                @click="closeModal"
              >
                Chiudi
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button
                color="success"
                size="default"
                expand="block"
                @click="savePhoto()"
              >
                Salva
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-grid>
                <ion-row>
                  <ion-col size="6" :key="photo" v-for="photo in userPhoto">
                    <ion-img
                      :src="photo.webviewPath"
                      class="img_photo"
                    ></ion-img>
                    <ion-fab-button
                      color="danger"
                      size="small"
                      @click="showActionSheet(photo)"
                      class="img_action"
                    >
                      <ion-icon :icon="trash"></ion-icon>
                    </ion-fab-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-fab horizontal="end" vertical="bottom" slot="fixed">
          <ion-fab-button color="primary" @click="showActionSheetPhotos()">
            <ion-icon :icon="camera"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
  IonFab,
  IonFabButton,
  actionSheetController,
  IonText,
  IonSpinner,
} from "@ionic/vue";
import { camera, trash, images } from "ionicons/icons";
import { defineComponent, ref, reactive, onMounted } from "vue";

import { openToast } from "../services/toast";

import apiInterventi from "../services/interventi";

import { usePhotoGallery, UserPhoto } from "../services/usePhotoGallery";

import imageToBase64 from "image-to-base64/browser";

export default defineComponent({
  name: "ImagesModal",
  props: {
    intervento_id: {
      type: String,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonIcon,
    IonFab,
    IonFabButton,
    IonText,
    IonSpinner,
  },
  setup(props, context) {
    //Camera functionality
    const { userPhoto, takePhoto, deletePhoto, pickPhotos } = usePhotoGallery();
    const sendingPhotos = ref(false);

    /**
     *
     * Handle close modal by clicking on Chiudi button or after upload foto
     * Send back data to visualize photos in InterventoDetail
     *
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    async function closeModalOnSubmit(res) {
      if (res) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     *
     * Handle camera and device selection photos
     *
     */
    const showActionSheetPhotos = async () => {
      const actionSheet = await actionSheetController.create({
        header: "Scegli modalità di caricamento",
        buttons: [
          {
            text: "Fotocamera",
            icon: camera,
            handler: () => {
              takePhoto();
            },
          },
          {
            text: "Galleria",
            icon: images,
            handler: () => {
              pickPhotos();
            },
          },
          {
            text: "Annulla",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    /**
     *
     * Handle delete single photo
     *
     */
    const showActionSheet = async (photo) => {
      const actionSheet = await actionSheetController.create({
        header: "Foto",
        buttons: [
          {
            text: "Elimina",
            role: "destructive",
            icon: trash,
            handler: () => {
              deletePhoto(photo);
            },
          },
          {
            text: "Annulla",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };

    // Iterates all photos, convert to base64 and return.
    const conversioneFoto = async (userPhoto) => {
      const requests = userPhoto.map((photo) => {
        return imageToBase64(photo.webviewPath) // Path to the image
          .then((response) => {
            //console.log(response);
            const fileName = photo.filepath;
            //fileName = fileName.substring(0, fileName.length - 5);
            //console.log(str);
            const foto = {
              name: fileName,
              data: response,
            };
            return foto;
          })
          .catch((error) => {
            console.error(error);
          });
      });
      return Promise.all(requests); // Waiting for all the requests to get resolved.
    };

    async function savePhoto() {
      if (userPhoto.value.length === 0) {
        openToast(
          "Non puoi salvare senza aver aggiunto almeno una foto",
          "danger"
        );
      } else {
        sendingPhotos.value = true; //loading invio foto
        const intervento = props.intervento_id;

        conversioneFoto(userPhoto.value).then((photosB64) => {
          //console.log(photosB64);
          apiInterventi
            .savePhotos(photosB64, intervento)
            .then((response) => {
              //console.log(response);
              //Se ha salvato devo mostrare toast, svuotare photos e chiudere la modale, tornando i dati (foto) caricate
              if (response.data.status === 1) {
                //openToast("Salvataggio foto effettuato", "success");
                userPhoto.value.length = 0;
                //closeModal();
                closeModalOnSubmit(response.data);
              } else {
                openToast("Errore durante il salvataggio foto", "danger");
              }
            })
            .catch((error) => {
              // handle error
              console.error(error);
              openToast("Errore durante il salvataggio foto", "danger");
            })
            .finally(() => {
              sendingPhotos.value = false;
            });
        });
      }
    }

    return {
      camera,
      trash,
      closeModal,
      closeModalOnSubmit,
      userPhoto,
      takePhoto,
      showActionSheet,
      savePhoto,
      sendingPhotos,
      pickPhotos,
      showActionSheetPhotos,
    };
  },
});
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img_photo {
  position: relative;
}
.img_action {
  position: absolute;
  top: -5px;
  right: -15px;
}

.select100 {
  max-width: 100% !important;
}

.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
  width: 100% !important;
}

.alert-wrapper {
  width: 90% !important;
}
</style>
