
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  IonButton,
  onIonViewWillEnter,
  IonIcon,
  IonImg,
  IonThumbnail,
  modalController,
} from "@ionic/vue";
import { checkmark } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

import moment from "moment";

import ImagesModal from "../components/ImagesModal.vue";

export default {
  name: "Tab2",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonList,
    IonItem,
    IonButton,
    IonIcon,
    IonImg,
    IonThumbnail,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id as string; //intervento_id by route params
    const loading = ref(false);
    const intervento = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    //let localInterventi = JSON.parse(localStorage.getItem("interventi"));
    //console.log(localInterventi);
    const customerNotSigned = ref(false);
    const operatorNotSigned = ref(false);
    const sendCustomerMail = ref(false);

    const interventoImmagini = ref([]);

    /**
     * Print name and last name or customer company based on condition
     */
    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }

    /**
     * Print Yes or No based on invia_mail value
     */
    const invioMail = computed(() => {
      return (intervento) => {
        if (intervento.interventi_invia_mail === "1") {
          return "Sì";
        } else {
          return "No";
        }
      };
    });

    const checkImpianto = computed(() => {
      return (impianto) => {
        if (impianto.projects_codice) {
          return `${impianto.projects_codice} - ${impianto.customers_address}`;
        } else {
          return `${impianto.customers_address}`;
        }
      };
    });

    /**
     * Return '-' if field is null
     */
    function checkField(field) {
      //console.log(field);
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     *
     * Set intevento images src attribute
     *
     */
    function setImage(imageSrc) {
      if (imageSrc) {
        return `https://crm.multilink.net/uploads/${imageSrc}`;
      }
    }

    /**
     *
     * Open modal to add images in intervento
     *
     */
    async function modalPhoto() {
      const modal = await modalController.create({
        component: ImagesModal, //Component to render inside ionic modal
        componentProps: {
          intervento_id: id,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail);
        if (detail.data != undefined) {
          openToast(detail.data.txt, "success");

          const editedIntervento = detail.data.intervento;
          interventoImmagini.value.length = 0;
          if (editedIntervento) {
            for (const [key, value] of Object.entries(
              editedIntervento["tickets_reports_immagini"]
            )) {
              //console.log(`${key}: ${value}`);
              interventoImmagini.value = [value, ...interventoImmagini.value];
            }
          }
        }
      });
      return modal.present();
    }

    const firmaCliente = () => {
      router.push({
        name: "FirmaCliente",
      });
    };

    const firmaOperatore = () => {
      router.push({
        name: "FirmaOperatore",
      });
    };

    /**
     * Send email to customer with PDF created with intervento details
     */
    function sendMail() {
      const idIntervento = id;
      const data = new FormData();
      data.append("tickets_reports_invia_mail", "1");

      apiInterventi
        .sendCustomerMail(data, idIntervento)
        .then((response) => {
          //console.log(response);
          openToast(
            "Verrà inviata una email al cliente con i dettagli dell'intervento",
            "success"
          );
          sendCustomerMail.value = true;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la modifica dell'intervento", "danger");
          //console.error(error);
        })
        .finally(() => {
          //console.log("fine modifica intervento per email cliente");
        });
    }

    function getIntervento() {
      loading.value = true;
      apiInterventi
        .getInterventoDetail(id, userID)
        .then((response) => {
          intervento.value = response;
          //ASSEGNO IMMAGINI INTERVENTO
          if (intervento.value["tickets_reports_immagini"]) {
            //console.log(intervento.value["tickets_reports_immagini"]);
            for (const [key, value] of Object.entries(
              intervento.value["tickets_reports_immagini"]
            )) {
              //console.log(`${key}: ${value}`);
              interventoImmagini.value = [value, ...interventoImmagini.value];
            }
            //console.log(interventoImmagini.value);
          }

          if (intervento.value["tickets_reports_firma_cliente_b64"]) {
            customerNotSigned.value = true;
          }
          if (intervento.value["tickets_reports_firma_operatore_b64"]) {
            operatorNotSigned.value = true;
          }
          if (intervento.value["tickets_reports_invia_mail"] === "0") {
            sendCustomerMail.value = false;
          } else {
            sendCustomerMail.value = true;
          }
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta degli interventi", "danger");
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onIonViewWillEnter(() => {
      getIntervento();
    });

    onMounted(() => {
      //localInterventi = JSON.parse(localStorage.getItem("interventi"));
      //GET INTERVENTI DATA
      //getIntervento();
      /*intervento.value = localInterventi.find(
        (intervento) => intervento.interventi_id === id
      );*/
    });

    return {
      loading,
      intervento,
      riferimentoCliente,
      dateFormat,
      checkField,
      checkImpianto,
      invioMail,
      //nuovi dopo aver aggiunti pulsanti in dett. intervento
      firmaCliente,
      firmaOperatore,
      //Flag for disabling button
      customerNotSigned,
      operatorNotSigned,
      checkmark,
      //invio mail al cliente
      sendMail,
      sendCustomerMail,
      //Scelta foto intervento
      modalPhoto,
      interventoImmagini,
      setImage,
    };
  },
};
